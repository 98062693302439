import React, { useState } from "react";
import FormHeading from "../FormComponents/FormHeading";
import "./Forms.css";
import PickTime from "../FormComponents/PickTime";

import {
  CancelButton,
  FormButtons,
  SubmitButton,
} from "../FormComponents/FormComponents";
import CustomCalendar from "../Calendar/CustomCalendar";
import SubmittedBox from "../FormComponents/SubmittedBox";
import ScheduledInfo from "../FormComponents/ScheduledInfo";
import axios from "axios";
import { base_url } from "../../config";

export default function ScheduleDemo({ setStep }) {
  const [dateSelected, setDateSelected] = useState(new Date());
  const [timeSelected, setTimeSelected] = useState("");
  const [timeZoneSelected, setTimeZoneSelected] = useState("");
  const [durationSelected, setDurationSelected] = useState("15min");
  const [submitted, setSubmitted] = useState(false);

  // Convert time into 24H format
  const convertTo24HourFormat = (time) => {
    const [timePart, modifier] = time.split(" ");
    let [hours, minutes] = timePart.split(":").map(Number);

    if (modifier === "PM" && hours < 12) hours += 12;
    if (modifier === "AM" && hours === 12) hours = 0;

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  };
  const submitData = async () => {
    const formData = JSON.parse(sessionStorage.getItem("formData")) || [];
    const storedQaList = JSON.parse(sessionStorage.getItem("qaList")) || [];
    // sessionStorage.clear();
    // Get Date as payload
    const formattedDate = `${dateSelected.getFullYear()}-${String(
      dateSelected.getMonth() + 1
    ).padStart(2, "0")}-${String(dateSelected.getDate()).padStart(2, "0")}`;
    const dateTime = `${formattedDate}T${convertTo24HourFormat(timeSelected)}`;

    //Meeting
    const meetingdata = {
      user_email: formData.email,
      date_time: dateTime,
      time_zone: timeZoneSelected,
      duration: durationSelected.slice(0, -2),
    };
    // Making fields as per payload of api for questions
    formData.firstname = formData.name;
    formData.company = formData.companyName;
    formData.jobtitle = formData.jobTitle;
    formData.department = formData.team;
    formData.industry_belongs_to = formData.industry;
    formData.country_dropdown = formData.country;

    // Removing keys that are different from keys in payload
    const removeKeys = [
      "name",
      "companyName",
      "jobTitle",
      "team",
      "industry",
      "country",
    ];
    const updatedFormData = Object.fromEntries(
      Object.entries(formData).filter(([key]) => !removeKeys.includes(key))
    );
      // Changing question to keys for api
      storedQaList[0].question = "which_segment_does_your_company_belong_to";
      storedQaList[1].question = "what_is_your_primary_goal_for_attending_this_finops_demo_";
      storedQaList[2].question = "which_cloud_providers_does_your_organization_currently_use_";
      storedQaList[3].question =
        "which_stage_of_finops_is_your_organization_currently_in_";
      storedQaList[4].question =
        "what_specific_challenges_are_you_facing_in_financial_operations_";

    const qaObject = storedQaList.reduce((acc, item) => {
      acc[item.question] = item.answer;
      return acc;
    }, {});

    // Combine formdata and questions list
    const formDataCombined = { ...updatedFormData, ...qaObject };

    // Converting into array
    const finalFormData = {
      fields: Object.entries(formDataCombined).map(([key, value]) => ({
        name: key,
        value: value,
      })),
    };
    try {
      const response = await axios.post(
        "https://api.hsforms.com/submissions/v3/integration/submit/8162471/4f82c690-6ef8-4b95-9ce4-5e6157a47274",
        finalFormData
      );
      console.log("Form Submitted ", response);
    } catch (error) {
      console.log(error.message);
      setStep(4);
    }
    try {
      const response = await axios.post(
        base_url + "v1/open/meeting",
        meetingdata
      );
      console.log("Meeting Data Submitted ", response);
      setSubmitted(true);
    } catch (error) {
      console.log(error.message);
      setStep(4);
    }
    sessionStorage.clear();
    console.log(meetingdata);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (timeSelected.charAt(0) === "N") {
      alert("No available time slots for the selected date.");
      return;
    }
    submitData();
  };
  return (
    <div className="schedule-demo-wrapper">
      <div className="schedule-demo-top">
        <div className="schedule-demo-left">
          <FormHeading
            h1="Schedule Demo"
            p="Book a demo by choosing your day"
          />
          <CustomCalendar setDateSelected={(date) => setDateSelected(date)} />
        </div>
        <div className="schedule-demo-right">
          <PickTime
            setTimeSelected={(time) => setTimeSelected(time)}
            setTimeZoneSelected={(timezone) => setTimeZoneSelected(timezone)}
            setDurationSelected={(duration) => setDurationSelected(duration)}
            selectedDate={dateSelected}
          />
        </div>
      </div>

      <div className="schedule-demo-bottom">
        <ScheduledInfo
          dateSelected={dateSelected}
          timeSelected={timeSelected}
          timeZoneSelected={timeZoneSelected}
        />
        <FormButtons>
          <CancelButton name="Back" onClick={() => setStep(2)} />
          <SubmitButton name="Schedule Demo" onClick={handleSubmit} />
        </FormButtons>
      </div>
      {submitted && (
        <SubmittedBox
          setStep={setStep}
          dateSelected={dateSelected}
          timeSelected={timeSelected}
          timeZoneSelected={timeZoneSelected}
        />
      )}
    </div>
  );
}
