import React from "react";
import ScheduledInfo from "./ScheduledInfo";
import submittedIcon from "../../assets/submittedIcon.svg";
export default function SubmittedBox({
  setStep,
  dateSelected,
  timeSelected,
  timeZoneSelected,
}) {
  return (
    <div className="submitted-box-overlay">
      <div className="submitted-box">
        <ScheduledInfo
          dateSelected={dateSelected}
          timeSelected={timeSelected}
          timeZoneSelected={timeZoneSelected}
        >
          <img src={submittedIcon} alt="Check Icon" />
        </ScheduledInfo>
        <div className="submitted-message">
          <h2>Demo Successfully Scheduled!</h2>
          <p>
            Our demo has been successfully booked. We look forward to showcasing
            how Xenonify AI can transform your business.
          </p>
          <button
            className="done-button"
            onClick={() => (window.location.href = "https://www.xenonify.ai/")}
          >
            <span>Done</span>
          </button>
        </div>
      </div>
    </div>
  );
}
